import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { createGraphqlMutationEither } from 'src/common/graphql/graphqlHelper';
import { tuple } from 'src/common/utils/tuple';

const mutationCreateShareVisitor = gql`
  mutation CreateShareVisitor($shareId: String!) {
    createShareVisitor(shareId: $shareId) {
      id
    }
  }
`;

interface Result {
  createShareVisitor: { id: string };
}

interface Variables {
  shareId: string;
}

export const useCreateShareVisitor = () => {
  const [createShareVisitor, result] = useMutation<Result, Variables>(
    mutationCreateShareVisitor
  );

  const memorizedCreateShareVisitor = useCallback(
    (shareId: string) =>
      createGraphqlMutationEither(createShareVisitor, {
        variables: { shareId },
      }),
    [createShareVisitor]
  );

  return tuple(memorizedCreateShareVisitor, result);
};
