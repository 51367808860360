import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { getSessionToken } from 'src/modules/Session/Selectors';
import { setIsNewSignUp, setShareId } from 'src/modules/Virality/Actions';
import { useCreateShareSignUp } from 'src/modules/Virality/hooks/useCreateShareSignUp';
import { useCreateShareVisitor } from 'src/modules/Virality/hooks/useCreateShareVisitor';
import { getIsNewSignUp, getShareId } from 'src/modules/Virality/Selectors';

export const RenderlessVirality = (): any => {
  const dispatch = useDispatch();
  const {
    query: { shareId: queryShareId },
  } = useRouter();
  const shareId = useSelector(getShareId);
  const isNewSignUp = useSelector(getIsNewSignUp);
  const hasToken = Boolean(useSelector(getSessionToken));
  const [createShareSignUp] = useCreateShareSignUp();
  const [createShareVisitor] = useCreateShareVisitor();

  useEffect(
    function setShareIdAndCreateShareVisitor() {
      if (!queryShareId) {
        return;
      }
      createShareVisitor(queryShareId as string);
      dispatch(setShareId(queryShareId as string));
    },
    [createShareVisitor, dispatch, queryShareId]
  );

  useEffect(
    function createShareSignUpEffect() {
      if (!isNewSignUp || !shareId || !hasToken) {
        return;
      }
      createShareSignUp(shareId);
      dispatch(setIsNewSignUp(false));
    },
    [shareId, isNewSignUp, createShareSignUp, dispatch, hasToken]
  );

  return null;
};
