import { useCallback } from 'react';
import { gql, MutationResult, useMutation } from '@apollo/client';
import { Either } from 'fp-ts/lib/Either';

import { createGraphqlMutationEither } from 'src/common/graphql/graphqlHelper';
import { tuple } from 'src/common/utils/tuple';

const mutationCreateShareSignUp = gql`
  mutation CreateShareSignUp($shareId: String!) {
    createShareSignUp(shareId: $shareId) {
      id
    }
  }
`;

interface Result {
  createShareSignUp: { id: string };
}

interface Variables {
  shareId: string;
}

export const useCreateShareSignUp = (): [
  (shareId: string) => Promise<Either<any, Result>>,
  MutationResult<Result>,
] => {
  const [createShareSignUp, result] = useMutation<Result, Variables>(
    mutationCreateShareSignUp
  );

  const memorizedCreateShareSignUp = useCallback(
    (shareId: string) =>
      createGraphqlMutationEither(createShareSignUp, {
        variables: { shareId },
      }),
    [createShareSignUp]
  );

  return tuple(memorizedCreateShareSignUp, result);
};
