import { MutationFunctionOptions } from '@apollo/client';
import { Either, left, right } from 'fp-ts/lib/Either';
import { ExecutionResult } from 'graphql';

export const createGraphqlMutationEither = async <Result, Variable>(
  mutation: (
    options?: MutationFunctionOptions<Result, Variable>
  ) => Promise<ExecutionResult<Result>>,
  options: MutationFunctionOptions<Result, Variable>
): Promise<Either<any, Result>> => {
  try {
    const { data } = await mutation(options);
    return right(data);
  } catch (err) {
    return left(err);
  }
};
